<template>
  <v-dialog v-model="mostrar" width="450" persistent>
    <v-card>
      <v-toolbar color="primary" dense flat dark>
        <v-toolbar-title>Remover examen</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <div class="py-2">
          <span
            >Esta acción solo quitará el examen de la lista de
            compartidos.</span
          >
        </div>
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            outlined
            small
            :disabled="loading"
            @click="$emit('cancelar')"
            class="mr-1"
            >Cancelar</v-btn
          >
          <v-btn
            small
            color="error"
            :disabled="loading"
            :loading="loading"
            @click="quitarExamen()"
          >
            <v-icon small class="mr-2">mdi-delete</v-icon>
            <span>Eliminar</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { quitarExamenCompartido } from "./examenes.service";

export default {
  props: {
    mostrar: { type: Boolean, default: false },
    examen: { type: Object, required: true },
  },

  data: () => ({
    loading: false,
  }),

  methods: {
    async quitarExamen() {
      this.loading = true;

      try {
        const idExamen = this.examen._id;

        const serverResponse = await quitarExamenCompartido(idExamen);
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.$emit("examenEliminado", serverResponse.examen);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
